.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.location {
  margin: {
    top: 1rem;
  }
}

.copy {
  &:hover {
    cursor: pointer;
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: {
    top: 1rem;
  }
}

.form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  flex-grow: 1;
}

.location {
  flex-grow: 1;
}

.attendance-form {
  flex-grow: 1;
}