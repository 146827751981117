.container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.course-item:hover {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.08);
}