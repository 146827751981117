.container {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.menu {
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 300px;
}