@import '../../styles/variables';

@mixin table-cell($padding: 0) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $padding;
}

.total {
  font-size: 20px;
  @include table-cell()
}

.attendance-cell {
  @include table-cell()
}

.date-range {
  text-align: center;
  @include table-cell(0.5rem)
}

.winning {
  background: lighten($ict-yellow, 35%);
}