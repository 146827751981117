.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
}

.delete-button {
  margin: 5px;
}

.delete-icon {
  font-size: 2rem;
}

.empty {
  text-align: center;
}

.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}