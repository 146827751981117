.scrolling-wrapper {
  display: grid;
  place-items: center;
  margin: 1rem;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}

.centering-wrapper {
  display: flex;
  justify-content: center;
}

.table {
  flex: 0 0 auto;
  margin: 1rem;
  display: grid;
  align-items: stretch;
  justify-content: center;
  grid-column-gap: 1rem;
  grid-auto-flow: column;
  grid-template-rows: auto repeat(var(--students-count), auto) auto;
  grid-template-columns: auto repeat(calc(var(--dates-count) + var(--number-of-attendance-form-columns)), auto) auto;
}

.student {
  z-index: 1000;
  min-width: 160px;
  text-align: right;
  align-self: center;
  position: sticky;
  left: 0;
  padding: {
    top: 1rem;
    bottom: 1rem;
    right: 1rem;
  }
}

.add-new-range {
  display: flex;
  align-items: center;
}

.attendance-form {
  display: flex;
  align-items: center;
}